.initial-user-info {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  background-color: #ffffff;
}

.initial-user-info h2 {
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
}

.initial-user-info .input_box {
  margin-bottom: 25px;
  text-align: left;
}

.initial-user-info .name-fields .input_box input{
    margin-bottom: 0px;
  }

.initial-user-info .input_box > label {
  display: block;
  margin-bottom: 10px;
  font-weight: 500;
}

.initial-user-info .input_box label {
  display: block;
  margin-bottom: 5px;
}

.initial-user-info .input_box label input[type="checkbox"] {
  margin-right: 5px;
}

.initial-user-info .input_box label + label {
  margin-top: 5px;
}

.initial-user-info input[type="text"],
.initial-user-info input[type="number"],
.initial-user-info select {
  width: 100%;
  height: 40px;
  padding: 10px;
  border: 1px solid #DADAF2;
  border-radius: 5px;
  font-size: 16px;
  background-color: #F8F8FB;
}

.initial-user-info input[type="text"]:focus,
.initial-user-info input[type="number"]:focus,
.initial-user-info select:focus {
  border-color: #626cd6;
  outline: none;
}

.initial-user-info .checkbox-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.checkbox-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 5px;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
}

.checkbox-item input[type="checkbox"] {
  margin-right: 10px;
}

.checkbox-item label {
  flex: 1;
}

/* Override the login page input styles for checkboxes */
.login-page form .input_box .checkbox-item input[type="checkbox"] {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  /* Reset other properties that might be affecting the checkbox */
  padding: 0;
  border: 1px solid #DADAF2;
  border-radius: 3px;
  background-color: #F8F8FB;
  margin-bottom: 0px;
}


.error-message {
  color: #ff0000;
  font-size: 14px;
  margin-bottom: 10px;
  text-align: center;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.spinner {
  border-color: #3498db;
}

.name-fields {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.name-fields .input_box {
  flex: 1;
}

.name-fields input[type="text"] {
  width: 100%;
}

.vietnamese-explain{
  font-size: 11px;
}