.guideline-container {
    max-width: max(800px, 75%);
    margin: 0 auto;
    padding: 20px;
    text-align: left;
    margin-top: 80px;
    font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI Variable Display", "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
}

.guideline_title {
    color: #1B65D4;
    font-size: 28px;
    margin-top: 40px;
    border-bottom: 2px solid #1B65D4;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.guideline-section {
    background: rgba(209, 209, 209, 0.607);
    border-radius: 5px;
    box-shadow: 0 2px 5px #e8e7e7;
    padding: 15px;
    width: 100%;
    text-align: left;
    margin-bottom: 30px;
}

.guideline-section h3 {
    color: #1B65D4;
    margin-top: 5px;
    font-weight: bold;
    margin-bottom: 15px;
}

.back-button {
    font-weight: bold;
    font-size: 14px;
    text-decoration: none;
    width: 15vw;
    height: 6vh;
    min-width: 100px;
    max-width: 200px;
    border-radius: 10px;
    border: #1B65D4 solid 1px;
    background-color: rgb(255, 255, 255);
    color: #1B65D4;
    margin: 5px auto;
    margin-bottom: 20px;
    margin-top: 50px;
    transition: background-color 0.3s, color 0.3s;
}

.back-button:hover {
    background-color: #155a9a;
    color: white;
}

ul {
    list-style-type: disc;
    padding-left: 20px;
    text-align: left;
    color: #333;
    line-height: 1.6;
    margin-bottom: 20px;
}

ul li {
    margin-bottom: 10px;
}

.guideline-container h2 {
    margin-top: 30px;
}

.back-to-home-button {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    font-size: 16px;
    color: #007bff;
    background-color: #ffffff;
    border: #007bff solid 1px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.back-to-home-button:hover {
    background-color: #e5e9ed;
}

.guideline-container h2{
    color: #1B65D4;
}