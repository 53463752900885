body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    line-height: 1.6;
    background-color: white;
    min-width: 300px;
    overflow-x: hidden;
}

main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 300px;
}

header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #EAF4FB;
    border-radius: 5px;
    height: 80px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); 
    margin-bottom: 0px;
}

#logo{
    font-family: "Yuji Boku", serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #1B65D4;
    font-size: 3.8vh;
    margin-top: 0px;
    margin-bottom: 0px;
    align-items: center;
    flex: 2;
    /* background-color: green; */
}

.logo_adjust{
    font-size: 3.8vh;
    margin-left: 5vw;
    user-select: none; /* Prevents text selection */
}

#event_guidelines{
    /* display: flex;
    justify-content: space-evenly;  */
    gap: 4vh;
    font-family: 24px;
    font-weight: bolder;
    color: black;
    display: flex;
    margin-top: 5px;
    margin-right: 40px;
    font-size: 18px;
    /* flex: 1; */
}

#event_guidelines a:hover {
    /* text-decoration: underline; */
    border-bottom: 1px solid #000000;
}

#event_guidelines a {
    text-decoration: none;
    color: rgb(81, 79, 79);
    font-weight: bold;
}

#account{
    display: none;
    justify-content: flex-end;
    align-items: center;
    gap: 2vh;
    /* margin-right: 0.5vw; */
    flex: 1;
}

#logged-in-header{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 2vh;
    /* margin-right: 0.5vw; */
    flex: 1;
}

#create_btn{
    width: 30vw;
    height: 40px;
    border-radius: 20px;
    color: white ;
    font-weight: bold;
    border: none;
    background: #1B65D4;
    font-size: 0.9rem;
    max-width: 140px;
    transition: background-color 0.3s, color 0.3s;
}

#create_btn:hover {
    background-color: #155a9a;
    color: #ffffff;
}

#login_btn{
    width: 106px;
    height: 40px;
    border-radius: 20px;
    color: #1B65D4 ;
    font-weight: bold;
    border: #1B65D4 solid 1px;
    background: #ffffff;
    font-size: 0.9rem;
    margin-right: 5vw;;
    transition: background-color 0.3s, color 0.3s;
}

#login_btn:hover {
    background-color: #e5e9ed;
    color: #1B65D4;
}

.hamburger-menu-container{
    display: flex;
    flex: 1;
    justify-content: flex-end;
}

/* Hamburger Menu */
.hamburger-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    width: 30px;
    height: 20px;
    margin-right: 5vw;
}

.hamburger-menu .line {
    height: 4px;
    background-color: #1B65D4;
    border-radius: 2px;
    width: 100%;
}

/* Hidden Menu */
.menu {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
}

.menu ul {
    list-style: none;
    padding: 20px;
    margin: 0;
    margin-top: 30px;
}

.menu ul li {
    margin: 15px 0;
}

.menu ul li a {
    text-decoration: none;
    color: black;
    font-size: 18px;
    font-weight: bold;
    border-bottom: 2px solid #1B65D4;
    padding-bottom: 2px;
    display: block;
    margin-top: 50px;
}

/* Menu Open State */
.menu.open {
    transform: translateX(0);
}

/* Close Button */
.close-menu {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 40px;
    font-weight: normal;
    color: #1B65D4;
    cursor: pointer;
    outline: none;
    transition: transform 0.5s;
}

.close-menu:hover {
    transform: scale(1.2); /* Slight zoom effect on hover */
}


.header-buttons {
    display: flex;
    justify-content: flex-end;
    max-width: 100%;
    flex: 2;
}

.header-buttons .login-signup button {
    margin-left: 15px;
    padding: 0px;
    padding-left:15px;
    padding-right:15px;
    height: 35px;
    font-size: 15px;
    font-weight: bold;
    color: #ffffff;
    transition: transform 0.1s ease; /* Smooth transition for the effect */
    background-color: #1B65D4;
    border: none;
    border-radius: 10px;
    max-width: 100%;
}

.header-buttons .login-signup .login{
    background-color: #dce1e2;
    border: #1B65D4 solid 1px;
    color: #1B65D4;
}

.header-buttons button:active {
    transform: scale(0.95); /* Slightly scales down the button when clicked */
    background-color: #626CD6
}

/* src/Components/Header/Header.css */
.user-menu {
    position: relative;
    display: inline-block;
    margin-right: 5vw;
    margin-top: 5px;
}

.user-icon {
    cursor: pointer;
    margin-right: 15px;
    
}

.dropdown-content {
    display: none;
    right: 15px;
    position: absolute;
    background-color: #f0ecec; /* Match the background color to InitialUserInfo */
    min-width: 100px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    width: 100%;
    border: 1px solid #DADAF2; /* Add border similar to InitialUserInfo inputs */
    border-radius: 5px; /* Add border-radius for a rounded effect */
    padding: 15px 10px 15px 10px;
}

.user-menu:hover .dropdown-content {
    display: block;
}

.dropdown-content button {
    color: #ffffff; /* Change text color for better contrast */
    display: block;
    border-radius: 5px;
    text-align: center;
    font-weight: bold;
    font-size: 15px; /* Match font size to InitialUserInfo inputs */
    background-color: #1B65D4; /* Match background color to InitialUserInfo inputs */
    padding: 0px 0px;
    margin: 0px 0px;
    width: 100%;
    height: 2.5rem;
    border: none;
}

.dropdown-content button:hover {
    background-color:#2c77d3;
    border-radius: 5px; /* Add border-radius for hover effect */

}

.dropdown-content button:active {
    transform: scale(0.95); /* Slightly scales down the button when clicked */
    background-color: #626CD6
}




  @media (min-width: 768px) {
    #event_guidelines{
        display: flex;
    }

    #account{
        display: flex;
    }

    .hamburger-menu {
        display: none;
    }

    .hamburger-menu-container{
        display: none;
    }

}