.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh; /* Ensure full viewport height */
    margin: 0; /* Remove default margins */
    padding: 0px 0px;
    background-color: #f5f5f5; /* Optional: Set a background color */
}

.login-page.loading {
    pointer-events: none;
}

.login-page.loading > *:not(.loading-overlay) {
    filter: blur(3px);
}

.login-page .login_form {
    width: 90%;
    max-width: 450px;
    background: #ffffff;
    border-radius: 6px;
    padding-top: 15px; /* Increased padding */
    padding-bottom: 25px; /* Increased padding */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column; /* Added to stack children vertically */
    align-items: center; /* Center items horizontally */
    /* border: red solid 1px; */
    position: relative; /* Set the form to relative positioning */
}

.login-page .login_form form {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
}

.login-page .login_form h2 {
    font-size: 25px;
    text-align: center;
    margin-top: 25px;
    margin-bottom: 25px;
}

/* Google & Apple button styling */
.login-page .login_form .login_option {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    /* Removed background-color: red; */
    max-width: 450px; /* Added max-width */
}
.login-page .login_form .login_option .social-media-space {
    margin-left: 5px;
}
/* .login-page .login_form .login_option a {
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    background: #F8F8FB;
    border: 1px solid #DADAF2;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    text-decoration: none;
    color: #171645;
    font-weight: 500;
    transition: 0.2s ease;
    width: 100%;
} */

.login-page .login_form .login_option a:hover {
    background: #ededf5;
    border-color: #626cd6;
}

.login-page .login_form .login_option a img {
    max-width: 25px;
}

.login-page .login_form p {
    text-align: center;
    font-weight: 500;
}

.login-page .login_form .separator {
    position: relative;
    margin-bottom: 25px;
    width: 100%;
    margin-top: 20px;
}

/* Login option separator styling */
.login-page .login_form .separator span {
    background: #ffffff;
    z-index: 1;
    padding: 0 10px;
    position: relative;
    text-align: center;
}

.login-page .login_form .separator::after {
    content: '';
    position: absolute;
    width: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 1px;
    display: block;
    border-bottom: 1px solid #525252;
}

.login-page form .input_box {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.login-page form .input_box label {
    font-weight: 500;
    margin-bottom: 5px;
}

/* Input field styling */
.login-page form .input_box input {
    height: 45px;
    border: 1px solid #DADAF2;
    border-radius: 5px;
    outline: none;
    background: #F8F8FB;
    font-size: 17px;
    margin-bottom: 25px;
    transition: 0.2s ease;
    width: 100%;
    padding: 0px 0px;
    text-indent: 10px;
}

.login-page form .input_box input:focus {
    border-color: #626cd6;
}

.login-page .login_form .resetpass-explain {
    margin-top: -15px;
    margin-bottom: 30px;
}

.login-page form .input_box .password_title {
    display: flex;
    justify-content: space-between;
    align-items:flex-start;
    width: 100%; /* Adjust width as needed */
}



.login-page form .input_box {
    position: relative;
    width: 100%; /* Ensure full width */
    /* Removed border: red solid 1px; */
}

.login-page a {
    text-decoration: none;
    color: #626cd6;
    font-weight: 500;
}

.login-page a:hover {
    text-decoration: underline;
}

.login-page .login-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

/* Login button styling */
.login-page button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    border-radius: 5px;
    border: none;
    outline: none;
    /* background: #626CD6; */
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    margin-bottom: 15px;
    transition: 0.3s ease;
    background-color: #1B65D4;
}

.login-page form button:hover {
    background: #155a9a;
}

.login-page form input::placeholder {
    font-size: 14px; /* Changed from 14px to 12px */
    padding-left: 5px;
}

button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.login-page .transit-login-signup{
    background-color: white;
    color: #1B65D4;;
    border: 1px solid #1B65D4 ;
}

.login-page .transit-login-signup:hover {
    background: #d6d6dd;
}

.forgot-password {
    align-self: flex-end;
    text-decoration: none;
    color: #626cd6;
    font-weight: 500;
    margin-bottom: 5px;
}

.forgot-password:hover {
    text-decoration: underline;
}

.error {
    color: #ff0000;
    margin-bottom: 15px;
    text-align: center;
}

.sign_up {
    text-align: center;
    font-weight: 500;
}

.sign_up a {
    color: #626cd6;
    text-decoration: none;
}

.sign_up a:hover {
    text-decoration: underline;
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.spinner {
    border-color: #3498db;
}

.error-msg {
    color: #ff0000;
    margin-bottom: 5px;
    text-align: center;
}

/* .google-signin-button{
    background-color: #938f8f;
    font-size: 14px;
    color: black;
} */
.login-page .login_form .social-login-button{
    font-size: 14px;
    color: #ffffff;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-bottom: 10px;
    width: 100%;
}

.social-login-button.google-button {
    background-color: #e6736f;
    color: #ffffff;
}

.social-login-button.google-button:hover {
    background-color: #db322c;
}

.social-login-button.facebook-button {
    background-color: #467ac0;
    color: #ffffff;
}

.social-login-button.facebook-button:hover {
    background-color: #166fe5;
}

.login-page .login_form p {
    padding: 0px;
    margin-top: 0px;
}

.error-message {
  color: #ff0000;
  font-size: 14px;
  margin-bottom: 10px;
  text-align: center;
}

/* Styles for ConfirmSignup component */
.confirm-signup-explain {
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
  text-align: center;
}

.resend-code {
  font-size: 14px;
  margin-top: 15px;
  text-align: center;
}

.resend-button {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 14px;
  padding: 0;
  text-decoration: underline;
}

.resend-button:hover {
  color: #0056b3;
}

.resend-link {
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}

.resend-link:hover {
  color: #0056b3;
}

.move-back-button{
    position: absolute;
    top: 10px;
    left: 0px;
    text-decoration: none;
    color: #626cd6;
    padding-left: 20px;
    margin-bottom: 30px;
    font-size: 15px;
}

.move-back-button:hover {
    text-decoration: none;
    color: #23296c;
}