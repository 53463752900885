footer {
    background-color: #333;
    color: white;
    text-align: center;
    padding: 1em 0;
    margin-top: 100px;
    padding-bottom: 30px;
    width: 100%;
}

footer a {
    color: white;
    margin: 0 0.5em;
    text-decoration: none;
    display: block;
}

#footer_link{
    display: flex;
    color: white;
    flex-direction: row;
    justify-content:space-around;
}

#footer_link div{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items:flex-start;
}

#footer_link a{
    margin-bottom: 10px;
}

.link-button:hover {
  color: lightgray;
}