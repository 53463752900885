.password-input-eye-container{
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.passwordinput{
    width: 100%;
    display: block;
}

.showpassword{
    position: absolute;
    right: 5px;
    top: 43%;
    text-align: center;
}