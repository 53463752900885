* {
    box-sizing: border-box;
}

body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    line-height: 1.6;
    background-color: white;
    min-width: 300px;
    overflow-x: hidden;
}

main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 300px;
    max-width: 100%;
}

#intro {
    text-align: center;
    margin-top: 90px;
    padding: 1em;
    margin-bottom: 2.5em;
}

#intro h2 {
    font-size: 1.8rem;
    margin-bottom: 10px;
}

#intro p {
    font-size: 14px;
}

#countdown {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: #b9d1e2 solid 1px;
    border-radius: 2vh;
    width: 85vw;
    max-width: 750px;
    margin: 5vh auto;
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: #EAF4FB;
    min-width: 300px;
    box-shadow: 0 8px 8px #1B65D4(0, 0, 0, 0.1);
}

#countdown_datatime{
    display: flex;
    justify-content: center;
    gap: 3vw;
    margin-top: 15px;
}

#countdown_datatime p {
    font-size: clamp(1rem, 1.8rem, 2.5rem);
    color: #FF4500 ;
}

#countdown_nation{
    display: flex;
    flex-direction: column;
    gap: 1.5vh;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    color: #FF4500 ;
}

#join_button{
    margin-top: 20px;
    margin-bottom: 40px;
    height: 50px;
    border-radius: 20px;
    background-color: #1B65D4;
    border: none;
    color: white;
    font-size: 2vw;
    font-size: clamp(1rem, 1.4rem, 3rem);

    width: 75vw;
    max-width: 450px;
    min-width: 225px;
    max-height: 65px;
}

#join_button:hover{
    background-color: #155a9a;
    cursor: pointer;
}

#next_event p{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: 0px;
    color:#000000;
    font-size: clamp(1rem, 1rem, 1.5rem);
    /* font-weight:bold; */
    width: 85vw;
    border-bottom-left-radius: 5vw;
    border-bottom-right-radius: 5vw;
    max-width: 750px;
    
}

#countdown h3{
    color: black;
    font-size: clamp(1.2rem, 1.2rem, 1.5rem);
    font-weight:bold;
    
    border-top-left-radius: 5vw;
    border-top-right-radius: 5vw;
    margin-top: 0px;
    width: 85vw;
    max-width: 720px;
    margin-bottom: 0vh;
    margin-top: 5px;
}

#defined {
    display: flex;
    flex-direction: column;
    justify-content:space-around;
    align-items: center;
    background-color: #FFF7EB;
    padding: 2vw;
    margin-top: 10vh;
    width: 100vw;
    min-width: 300px;
    max-width: 100%;
    margin-bottom: 40px;
}

#defined_text{
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    text-align: center;
}

#defined_text p{
    display: block;
    margin-left: 0px;
    text-align: center;
    font-size: 14px;
}

#general_number{
    display: flex;
    padding: 5px;
    justify-content: space-around;
    align-items: center;
    gap: 3vw;
}

#general_number .box{
    position: relative;
    border: #1B65D4 solid 2px;
    width: 22vw;
    height: 14vw;
    border-radius: min(3vw, 30px);
    /* font-size: 24px; */
    font-size: calc(var(--heading-font-size));
    color: #1B65D4;
    max-width: 180px;
    max-height: 120px;
    min-width: 90px;
    min-height: 60px;
}

#general_number .box .box_content{
    position: absolute;
    top: 50%;          
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

#general_number .box .box_explain{
    position: absolute;
    bottom: 0.1%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    font-size: 10px;
    margin-bottom: 0.5px;
}

#who-is-participant{
    display: flex;
    flex-direction: column;
}

:root {
    --heading-font-size: 24px;
}

.heading_section {
    position: relative;
    font-size: var(--heading-font-size);
    text-align: center;
    margin-top: 12vh;
    color: #1B65D4;

}

.defined_heading{
    font-size: var(--heading-font-size);
    text-align: center;
    margin-top: 2vh;
    color: black;
}

.additional-info-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#who-is-participant, #event-rule, #how-to-join {
    display: flex;
    flex-direction: column;    
    justify-content: center;
    align-items: center;
    max-width: 1000px;
    width:90%;
}

.participant-content{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3vh;
}

#participant{
    flex: 3;
}

/* #who-is-paticipant h2{
    text-align: center;
    color: #1B65D4;
    margin-top: 10vh;
    margin-bottom: 10px;
} */

/* #participant {
    display: flex;
    justify-content:space-around;
    align-items: center;
    gap: 1vw;
} */

/* .content {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 100%;
    text-align: left;
    font-size: 1.1rem;
    line-height: 1.6;
} */

.content li{
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
}


#participant .image {
    flex: 1;
}

.image{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    height: 100%;
    text-align: left;
    max-width: 170px;
}

.image img {
    max-width: 100%;
    min-width: 50%;
    height: auto;
}

#participant img {
    max-width: 100%;
    height: auto;
}

#how-to-join{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}


footer a {
    color: white;
    margin: 0 0.5em;
    text-decoration: none;
    display: block;
}

#footer_link{
    display: flex;
    color: white;
    flex-direction: row;
    justify-content:space-around;
}

#footer_link div{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items:flex-start;
}

#footer_link a{
    margin-bottom: 10px;
}

@media (min-width: 640px) {
    
    #countdown_datatime p {
        font-size: 36px;
    }

    #countdown_nation{
        font-size: 24px;
    }

    #join_button{
        font-size: 26px;
        height: 55px;
    }

    #countdown h3{
        font-size: 18px;
    }

    #next_event p{
        font-size: 18px;
    }

    #intro h2 {
        font-size: 2.4rem;
    }

    #intro p, #defined_text p {
        font-size: 1rem;
    }

    :root {
        --heading-font-size: 26px;
    }

    #general_number .box .box_explain{
        font-size: calc(var(--heading-font-size)/1.6);
    }
    
    .participant-content{
        gap: 15vw;
    }
    .content{
        font-size: 1.2rem;
    }
    .content li{
        font-size: 1rem;
        padding-bottom: 15px;
    }

  }
  
  @media (min-width: 768px) {
    #event_guidelines{
        display: flex;
    }

    #account{
        display: flex;
    }

    .hamburger-menu {
        display: none;
    }


    #countdown_datatime p {
        font-size: 38px;
    }

    #countdown_nation{
        font-size: 26px;
    }

    #join_button{
        font-size: 26px;
        height: 55px;
    }

    #countdown h3{
        font-size: 20px;
    }

    #next_event p{
        font-size: 20px;
    }

    #intro h2 {
        font-size: 2.4rem;
    }

    :root {
        --heading-font-size: 28px;
    }

    #general_number .box .box_explain{
        font-size: calc(var(--heading-font-size)/1.8);
    }

    .participant-content{
        gap: 15vw;
    }
    .content{
        font-size: 1.2rem;
    }
}

  @media (min-width: 1024px) {
    #countdown_datatime p {
        font-size: 40px;
    }

    #countdown_nation{
        font-size: 28px;
    }

    #join_button{
        font-size: 28px;
        height: 60px;
    }

    #countdown h3{
        font-size: 20px;
    }

    #next_event p{
        font-size: 20px;
    }

    #intro h2 {
        font-size: 2.4rem;
    }

    #intro p, #defined_text p {
        font-size: 1.2rem;
    }

    :root {
        --heading-font-size: 30px;
    }

    #general_number .box .box_explain{
        font-size: calc(var(--heading-font-size)/1.8);
    }

    .participant-content{
        gap: 15vw;
    }
    .content{
        font-size: 1.2rem;
    }
    .content li{
        font-size: 1.2rem;
        padding-bottom: 15px;
    }

  }

  @media (min-width: 1280px) {
    #countdown_datatime p {
        font-size: 42px;
    }

    #countdown_nation{
        font-size: 30px;
    }

    #join_button{
        font-size: 28px;
        height: 60px;
    }

    #countdown h3{
        font-size: 22px;
    }

    #next_event p{
        font-size: 22px;
    }

    #intro h2 {
        font-size: 2.8rem;
    }



    :root {
        --heading-font-size: 32px; 
    }

    #general_number .box .box_explain{
        font-size: calc(var(--heading-font-size)/2.5);
    }

    .participant-content{
        gap: 15vw;
    }
    .content{
        font-size: 1.2rem;
    }
  }

.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}